import React, { useState } from "react";
import { WizardContext } from "./store/wizard.store";
import { WizardContainer } from "./WizardContainer";

export const FtvWebBookingWizardPage: React.VFC<FtvWebBookingWizardPageProps> = (props) => {
    const [wizardFormData, setWizardFormData] = useState<WizardFormData>({
        selectedClinics: props.additionalClinicInformation.multiCheckboxCollection.flatMap(x =>
            x.multiCheckboxItem.selected ? [{
                value: x.multiCheckboxItem.checkboxValue,
                label: x.multiCheckboxItem.checkboxText,
            } as Option] : []
        ),
        selectedClinicsIds: [],
        selectedDate: new Date(),
        currentPageId: props.currentPageId,
        selectedTreatmentType: { id: 0, description: "", content: "" },
        selectedTimeSlot: {} as TimeSlot,
        personalNumber: "",
        altPhonenumber: "",
        eMail: "",
        messageForClinic: "",
        mobilePhonenumber: "",
        confirmationInSms: false,
        validationErrors: {},
        searchResult: [],
        lastMinuteSearchResult: {} as TimeSlot,
        showSearchResult: false,
        getInformationFromSkatteverket: false,
        name: "",
        lastName: "",
        isBookingWithCode: false,
        bookingCode: "",
        showRelatedClinics: false,
        activePage: 0,
        under20ValidationError: props.under20ValidationError,
        getInformationFromSkatteverketValidationError: props.getInformationFromSkatteverketValidationError,
        resultCount: 0
    });

    const formDataValue = { wizardFormData, setWizardFormData };

    return (
        <WizardContext.Provider value={formDataValue}>
            <div className="grid container p-0 ftvwebbooking">
                <div className="grid g-col-12">
                    <div className="ftvwebbooking__ingress g-col-12">
                        <h1 id="h1id">{props.title}</h1>
                        {wizardFormData.activePage == 0 && <p> {props.ingress}</p>}
                    </div>
                </div>

                <WizardContainer chevronIconSvg={props.chevronIconSvg}
                    additionalClinicInformation={props.additionalClinicInformation}
                    treatmentTypes={props.treatmentTypes}
                    antiforgeryToken={props.antiforgeryToken}
                    currentPageId={props.currentPageId}
                    radioButtonsTitle={props.radioButtonsTitle}
                    infoTextSearchResult={props.infoTextSearchResult}
                    searchResultIcon={props.searchResultIcon}
                    infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                    lastMinuteResultIcon={props.lastMinuteResultIcon}
                    activateLastMinute={props.activateLastMinute}
                    infoTextMessageToClinic={props.infoTextMessageToClinic}
                    infoTextFtvOnline={props.infoTextFtvOnline}
                    ftvOnlineLink={props.ftvOnlineLink}
                    infoTextBookingCode={props.infoTextBookingCode}
                    infoTextGetPersonalData={props.infoTextGetPersonalData}
                    infoTextTreatmentTypes={props.infoTextTreatmentTypes}
                    titleBookingCode={props.titleBookingCode}
                    ftvOnlineTitle={props.ftvOnlineTitle}
                    dentistTreatmentTypeId={props.dentistTreatmentTypeId}
                    hygienistTreatmentTypeId={props.hygienistTreatmentTypeId}
                    majorTroubleTreatmentTypeId={props.majorTroubleTreatmentTypeId}
                    calendarIcon={props.calendarIcon}
                    placeAndPerformerIcon={props.placeAndPerformerIcon}
                    postUrl={props.postUrl}
                    personalInfoUrl={props.personalInfoUrl}
                />
            </div>
        </WizardContext.Provider >
    );
}