import Select, { ActionMeta } from "react-select";
import { swedishAriaLiveMessages } from "../SearchService/shared/swedishAriaLiveMessages";
import DatePicker, { registerLocale } from "react-datepicker";
import { sv } from 'date-fns/locale/sv';
import { WizardContext } from "./store/wizard.store";
import { useContext, useEffect, useRef, useState } from "react";
import { WizardSearchResult } from "./WizardSearchResult";
import { calculateWeeks } from "./utils/WeekUtil";
registerLocale('sv', sv)

export const WizardSearchStep: React.VFC<WizardSearchStepProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const [isLoadingLastMinute, setIsLoadingLastMinute] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [errorHeader, setErrorHeader] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    //This is only used for the related clinics feature, we want to keep track if the selected clinics have any related clinics.
    const [selectedClinics, setSelectedClinics] = useState([] as AdditionalClinicInformation[]);
    const [selectedRelatedClinics, setSelectedRelatedClinics] = useState([] as AdditionalClinicInformation[]);
    const ref = useRef<HTMLInputElement>(null);
    const SSR = typeof window === 'undefined';

    const getSelectOptions = (wizardFormData: WizardFormData, additionalClinicInformation: AdditionalClinicCollection) => {
        if (wizardFormData.isBookingWithCode || wizardFormData.selectedTreatmentType.id === props.dentistTreatmentTypeId) {
            return additionalClinicInformation.multiCheckboxCollection.map(x => {
                return {
                    value: x.multiCheckboxItem.checkboxValue,
                    label: x.multiCheckboxItem.checkboxText,
                } as Option;
            }).sort((a, b) => a.label.replace("Folktandvården", "").localeCompare(b.label.replace("Folktandvården", ""), 'sv', { ignorePunctuation: true }));
        } else if (wizardFormData.selectedTreatmentType.id === props.hygienistTreatmentTypeId) {
            return additionalClinicInformation.additionalClinicPagesHygienist.map(x => {
                return {
                    value: x.multiCheckboxItem.checkboxValue,
                    label: x.multiCheckboxItem.checkboxText,
                } as Option;
            }).sort((a, b) => a.label.replace("Folktandvården", "").localeCompare(b.label.replace("Folktandvården", ""), 'sv', { ignorePunctuation: true }));
        } else if (wizardFormData.selectedTreatmentType.id === props.majorTroubleTreatmentTypeId) {
            return additionalClinicInformation.additionalClinicPagesMajorTrouble.map(x => {
                return {
                    value: x.multiCheckboxItem.checkboxValue,
                    label: x.multiCheckboxItem.checkboxText,
                } as Option;
            }).sort((a, b) => a.label.replace("Folktandvården", "").localeCompare(b.label.replace("Folktandvården", ""), 'sv', { ignorePunctuation: true }));
        }
    };

    const getAdditionalClinicInfo = (formData: WizardFormData, additionalClinicInformation: AdditionalClinicCollection): AdditionalClinicInformation[] => {
        if (formData.isBookingWithCode || formData.selectedTreatmentType.id === props.dentistTreatmentTypeId) {
            return additionalClinicInformation.multiCheckboxCollection;
        } else if (formData.selectedTreatmentType.id === props.hygienistTreatmentTypeId) {
            return additionalClinicInformation.additionalClinicPagesHygienist;
        } else if (formData.selectedTreatmentType.id === props.majorTroubleTreatmentTypeId) {
            return additionalClinicInformation.additionalClinicPagesMajorTrouble;
        }
    };

    const selectOptions: Option[] = getSelectOptions(wizardFormData, props.additionalClinicInformation);

    useEffect(() => {
        wizardFormData.isBookingWithCode && getTimesForBookingWithCode();
    }, [])

    const selectOnChange = (
        option: readonly Option[],
        actionMeta: ActionMeta<Option>
    ) => {
        const options =
            option.length > 0
                ? (option.map((checkbox) => ({
                    value: checkbox.value,
                    label: checkbox.label,
                })) as Option[])
                : [];

        options.map((checkbox) => {
            const additionalClinicInformation = getAdditionalClinicInfo(wizardFormData, props.additionalClinicInformation);

            const clinic = additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == checkbox.value);

            if (clinic &&
                !selectedClinics.find(x => x.multiCheckboxItem.checkboxValue == clinic.multiCheckboxItem.checkboxValue)) {
                setSelectedClinics((prevState) => [...prevState, clinic]);
            }
        });


        setWizardFormData((prevState) => ({ ...prevState, selectedClinics: options }));
    };

    const onChangeDate = (date) => {
        setWizardFormData((prevState) => ({ ...prevState, selectedDate: date }));
    };

    const onClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, searchResult: [], lastMinuteSearchResult: {} as TimeSlot, showSearchResult: true }));
        if (wizardFormData.isBookingWithCode) {
            getTimesForBookingWithCode();
        } else {
            getSearchResult();
        }
    }

    const getSearchResult = async (request?: WizardSearchRequest) => {
        setWizardFormData((prevState) => ({ ...prevState, searchResult: [] }));

        if (!request) {
            request = {
                currentPageId: wizardFormData.currentPageId,
                selectedClinics: wizardFormData.selectedClinics.map(x => x.value),
                selectedDate: wizardFormData.selectedDate,
                selectedDateEnd: wizardFormData.selectedDate,
                SelectedTreatmentType: wizardFormData.selectedTreatmentType,
                skip: 0,
                newRequest: true
            };
        }

        setSkip(0);

        props.activateLastMinute && setIsLoadingLastMinute(true);
        setIsLoading(true);

        let { currentWeekStart, currentWeekEnd, weeks } = calculateWeeks(request);

        let foundTimeSlot = 0;

        if (!request.newRequest) foundTimeSlot = wizardFormData.resultCount;

        for (let i = 0; i < weeks; i++) {
            request.selectedDate = currentWeekStart;
            request.selectedDateEnd = currentWeekEnd;

            for (let selectedClinic of request.selectedClinics) {
                request.selectedClinic = selectedClinic;

                let count = await getTimes(request);

                foundTimeSlot += count;

                setWizardFormData((prevState) => ({ ...prevState, resultCount: prevState.resultCount + count }));

                request.newRequest = false;
            }

            if (foundTimeSlot >= 10) break;

            currentWeekStart.setDate(currentWeekStart.getDate() + 7);
            currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
            request.newRequest = false;
        }

        setIsLoading(false);

        if (foundTimeSlot === 0) {
            setWizardFormData((prevState) => ({ ...prevState, searchResult: null }));
        }
    }

    const getTimesForBookingWithCode = async (request?: WizardSearchRequest) => {
        setWizardFormData((prevState) => ({ ...prevState, showSearchResult: true }));

        if (!request) {
            request = {
                currentPageId: wizardFormData.currentPageId,
                selectedDate: wizardFormData.selectedDate,
                selectedDateEnd: wizardFormData.selectedDate,
                SelectedTreatmentType: wizardFormData.selectedTreatmentType,
                skip: 0,
                bookingCode: wizardFormData.bookingCode.trim(),
                personalNumber: wizardFormData.personalNumber.trim(),
                newRequest: true
            };
        }

        setSkip(0);

        props.activateLastMinute && setIsLoadingLastMinute(true);
        setIsLoading(true);

        let { currentWeekStart, currentWeekEnd, weeks } = calculateWeeks(request);

        let foundTimeSlot = 0;

        for (let i = 0; i < weeks; i++) {
            request.selectedDate = currentWeekStart;
            request.selectedDateEnd = currentWeekEnd;

            let count = await getTimes(request);

            foundTimeSlot += count;

            if (foundTimeSlot >= 10) break;

            currentWeekStart.setDate(currentWeekStart.getDate() + 7);
            currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
            request.newRequest = false;
        }

        setIsLoading(false);

        if (foundTimeSlot === 0) {
            setWizardFormData((prevState) => ({ ...prevState, searchResult: null }));
        }
    }

    const getTimes = async (request: WizardSearchRequest): Promise<number> => {
        let httpRequest = {
            method: "POST",
            headers: {
                "Requestverificationtoken": props.antiforgeryToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        };

        let apiUrl = wizardFormData.isBookingWithCode ? "/FtvBookingWizard/GetTimesForBookingCode?vgrform=1" : "/FtvBookingWizard/GetTimes?vgrform=1";

        let responseJson = await fetch(apiUrl, httpRequest)
            .then(data => data.text());

        if (responseJson) {
            let response: TimeSlotResponse = JSON.parse(responseJson);

            if (!response.errorMessage && response.timeSlots && response.timeSlots.length > 0) {
                let newTimeslots = [];

                if (!request.newRequest) {
                    setWizardFormData((prevState) => {
                        let sortedTimeSlots = response.timeSlots.toSorted((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
                        newTimeslots = [...prevState.searchResult, ...sortedTimeSlots];
                        newTimeslots = newTimeslots.toSorted((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
                        return { ...prevState, searchResult: newTimeslots };
                    });
                } else {
                    newTimeslots = response.timeSlots.toSorted((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
                    setWizardFormData((prevState) => ({ ...prevState, searchResult: newTimeslots }));
                }
                if (wizardFormData.isBookingWithCode) {
                    setWizardFormData((prevState) => ({ ...prevState, selectedClinics: [selectOptions.find(x => x.value == response.timeSlots[0].healthcareFacilityId)] }));
                }
            } else if (response && response.errorMessage) {
                setErrorHeader(response.errorHeader);
                setErrorMessage(response.errorMessage);
            }

            return response.timeSlots.length;
        }

        return 0;
    }

    const onChangeRelatedClinics = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            let combinedClinics = wizardFormData.selectedClinics;

            combinedClinics.forEach(selectedClinic => {
                const additionalClinicInformation = getAdditionalClinicInfo(wizardFormData, props.additionalClinicInformation);
                const selectedClinicInfo = additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == selectedClinic.value);

                selectedClinicInfo?.multiCheckboxItem.relatedClinics?.forEach(clinicValue => {
                    const relatedClinic = additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == clinicValue);
                    if (relatedClinic && !combinedClinics.some(x => x.value === relatedClinic.multiCheckboxItem.checkboxValue)) {
                        combinedClinics.push({ value: relatedClinic.multiCheckboxItem.checkboxValue, label: relatedClinic.multiCheckboxItem.checkboxText });
                        setSelectedRelatedClinics(prevState => [...prevState, relatedClinic]);
                    }
                });
            });

            setWizardFormData((prevState) => ({
                ...prevState,
                showRelatedClinics: !prevState.showRelatedClinics,
                searchResult: [],
                lastMinuteSearchResult: {} as TimeSlot,
                selectedClinics: combinedClinics,
                newRequest: true
            }));

            getSearchResult({
                currentPageId: wizardFormData.currentPageId,
                selectedClinics: combinedClinics.map(x => x.value),
                selectedDate: wizardFormData.selectedDate,
                selectedDateEnd: wizardFormData.selectedDate,
                SelectedTreatmentType: wizardFormData.selectedTreatmentType,
                skip: 0,
                newRequest: true
            });
        } else {
            let combinedClinics = wizardFormData.selectedClinics;

            for (let clinic of selectedRelatedClinics) {
                let relatedClinics = combinedClinics.filter(x => x.value == clinic.multiCheckboxItem.checkboxValue);
                combinedClinics = combinedClinics.filter(x => !relatedClinics.includes(x));
            }

            setWizardFormData((prevState) => ({
                ...prevState,
                showRelatedClinics: !prevState.showRelatedClinics,
                searchResult: [],
                lastMinuteSearchResult: {} as TimeSlot,
                selectedClinics: combinedClinics
            }));

            getSearchResult({
                currentPageId: wizardFormData.currentPageId,
                selectedClinics: combinedClinics.map(x => x.value),
                selectedDate: wizardFormData.selectedDate,
                selectedDateEnd: wizardFormData.selectedDate,
                SelectedTreatmentType: wizardFormData.selectedTreatmentType,
                skip: 0,
                newRequest: true
            });
        }
    }

    const checkboxKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
        if (e.key === " " || e.key === "Spacebar") {
            e.preventDefault();
            ref.current.click();
        }
    };

    return (
        <div className="grid g-col-12 ftvwebbooking__search-step">
            <div className="grid g-col-12 ftvwebbooking__search-header">
                <div className="g-col-12 g-col-lg-8">
                    <label htmlFor="clinics">Sök efter klinik</label>
                    {!SSR ? <Select
                        isMulti={true}
                        id={"clinics"}
                        closeMenuOnSelect={false}
                        name={"clinics"}
                        options={selectOptions}
                        value={wizardFormData.selectedClinics}
                        onChange={selectOnChange}
                        placeholder={'Välj klinik...'}
                        defaultValue={selectOptions[0]}
                        ariaLiveMessages={swedishAriaLiveMessages}
                        isDisabled={(isLoading || isLoadingLastMinute) || wizardFormData.isBookingWithCode}
                        className="multi-select"
                        classNames={{
                            control: function (state) {
                                if (state.isDisabled) {
                                    return 'multi-select__control multi-select--loading'
                                }
                                return state.isFocused ? 'multi-select__control multi-select__control--focus' : 'multi-select__control';
                            },
                            menuList: (state) => 'multi-select__menu-list',
                            option: (state) => 'multi-select__option',
                            indicatorsContainer: (state) => 'multi-select__indicators-container',
                            multiValue: (state) => 'multi-select__multi-value',
                            multiValueLabel: (state) => 'multi-select__multi-value-label',
                            multiValueRemove: (state) =>
                                state.isFocused
                                    ? 'multi-select__multi-value-remove multi-select__multi-value-remove--focus'
                                    : 'multi-select__multi-value-remove',
                            clearIndicator: (state) => 'multi-select__clear-indicator',
                            placeholder: (state) => 'multi-select__placeholder'
                        }}
                        autoFocus
                        aria-label="Sök efter lediga tider på vald klinik"
                    /> : null}
                </div>
                <div className="g-col-12 g-col-lg-2">
                    <label htmlFor="datepicker">Välj datum</label>
                    <DatePicker
                        id="datepicker"
                        wrapperClassName="vgr-datepicker"
                        selected={wizardFormData.selectedDate}
                        onChange={onChangeDate}
                        shouldCloseOnSelect={false}
                        disabled={isLoading || isLoadingLastMinute}
                        disabledKeyboardNavigation
                        locale="sv"
                        minDate={new Date()}
                        dateFormat="yyy/MM/dd"
                    />
                    <DatePicker
                        id="mobiledatepicker"
                        selected={wizardFormData.selectedDate}
                        onChange={onChangeDate}
                        shouldCloseOnSelect={false}
                        disabled={isLoading || isLoadingLastMinute}
                        disabledKeyboardNavigation
                        locale="sv"
                        minDate={new Date()}
                        dateFormat="yyy/MM/dd"
                        withPortal
                    />
                </div>
                <div className="ftvwebbooking__header-button-container g-col-12 g-col-lg-2 g-start 10">
                    <button className="button-primary" type="button" onClick={onClickSearch} disabled={isLoading || isLoadingLastMinute} aria-controls={wizardFormData.showSearchResult ? "tiderCount" : ""}>Sök tider</button>
                </div>
            </div>
            {wizardFormData.showSearchResult &&
                !wizardFormData.isBookingWithCode &&
                selectedClinics.find(x => x.multiCheckboxItem.relatedClinics && x.multiCheckboxItem.relatedClinics.length > 0) &&
                <div className="g-col-12 ftvwebbooking__switch-container">
                    <label className="vgr-switch"
                        onKeyDown={checkboxKeyDown}
                        aria-checked={ref.current?.checked}
                    >
                        Visa relaterade kliniker
                        <input
                            ref={ref}
                            disabled={isLoading}
                            type="checkbox"
                            tabIndex={-1}
                            defaultChecked={wizardFormData.showRelatedClinics}
                            name="relatedClinics"
                            onChange={(e) => onChangeRelatedClinics(e)}
                            aria-controls={wizardFormData.showSearchResult ? "tiderCount" : ""}
                        />
                        <span className="vgr-switch__ui"></span>
                    </label>
                </div>}
            {wizardFormData.showSearchResult && <WizardSearchResult
                infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                infoTextSearchResult={props.infoTextSearchResult}
                lastMinuteResultIcon={props.lastMinuteResultIcon}
                searchResultIcon={props.searchResultIcon}
                isLoading={isLoading}
                isLoadingLastMinute={isLoadingLastMinute}
                setIsLoading={setIsLoading}
                skip={skip}
                setSkip={setSkip}
                activateLastMinute={props.activateLastMinute}
                additionalClinicInformation={getAdditionalClinicInfo(wizardFormData, props.additionalClinicInformation)}
                isBookingWithCode={wizardFormData.isBookingWithCode}
                errorHeader={errorHeader}
                errorMessage={errorMessage}
                setErrorHeader={setErrorHeader}
                setErrorMessage={setErrorMessage}
                relatedClinics={selectedRelatedClinics}
                getTimes={getTimes}
                calendarIcon={props.calendarIcon}
                placeAndPerformerIcon={props.placeAndPerformerIcon}
            />}
        </div>
    );
}